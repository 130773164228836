/* CSS Document */

h1 {
  font-size: 16px;
  font-weight: bold;
  padding: 0 10px 0 20px;
  height: 50px;
  line-height: 50px;
  /* background: url(../img/top_bg2.jpg) no-repeat top left; */
}

.j14 {
  /* font-size: 14px; */
  line-height: 12px;
}

.kome {
  color: #cc0000;
}

.left {
  float: left;
  margin-left: 30px;
  margin-right: 100px;
  margin-bottom: 15px;
}

/*-----2014.05.09 システム移行用-----------*/
#dlpre {
  width: 750px;
  margin: 0 auto;
}
/*--------------------------------------*/

#dlpre #lead {
  width: 730px;
  margin: 25px 10px 0 10px;
}

#dlpre #lead .left {
  float: left;
  width: 380px;
}

#dlpre #lead .right {
  float: right;
  width: 120px;
  padding-left: 10px;
}

#dlpre #lead .left p {
  margin: 10px 0;
}

#dlpre #lead .kigen {
  padding: 5px;
  background: #cc0000;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  margin-top: 16px;
}

#dlpre #enter {
  width: 600px;
  margin: 25px 80px 25px 80px;
  text-align: center;
  border: 0px;
}

#dlpre #file {
  width: 600px;
  margin: 25px 75px 25px 75px;
  border: 2px solid #ff9f39;
  background-color: #fff8ed;
}

#dlpre #file .midashi {
  font-weight: bold;
  padding: 3px 10px;
  color: #3e4e99;
  border-bottom: 1px solid #999999;
  border-left: 7px solid #3e4e99;
}

#dlpre #file #inner {
  margin: 15px 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #999999;
}

#dlpre #file #inner2 {
  margin: 15px 10px;
}

#dlpre #file #inner p,
#dlpre #file #inner2 p {
  margin: 0;
}

#dlpre #file #inner dl {
  margin-bottom: 0;
}

#dlpre #file #inner dt {
  float: left;
  width: 245px;
  vertical-align: middle;
}

#dlpre #file #inner dd {
  margin: 0 0 0 245px;
  vertical-align: middle;
}

#dlpre #file #inner dt span,
#dlpre #file #inner2 p span {
  font-weight: bold;
}

#dlpre #file #inner .btndl {
  margin-bottom: 7px;
}

#dlpre #file #inner2 .btndl {
  vertical-align: middle;
  margin-left: 15px;
}

#dlpre #note dl {
  width: 500;
  margin: 0 15px 40px 35px;
}

#dlpre #note dt {
  color: #cc0000;
  width: 15px;
  float: left;
}

#dlpre #note dd {
  margin-left: 15px;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* for IE 7*/
.clearfix {
  display: inline-block;
  min-height: 1%;
}

/* Hides from IE-mac */
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}

body,
html {
  height: 100%;
}

#container {
  width: 100%;
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 94%;
}

#contents {
  padding-bottom: 10px;
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10px;
}

/** from layout.css ***/

a:link {
  color: #0041a5;
  text-decoration: none;
}

a:visited {
  color: #0041a5;
  text-decoration: none;
}

a:hover {
  color: #0041a5;
  text-decoration: underline;
}

dl,
dt,
dd,
ul,
ol,
li,
form,
th,
td,
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  list-style: none;
}

/** Quilt Editor Custom Styles **/

.ql-container {
  min-height: 20em;
}

.ql-editor .ql-video {
  width: 640px;
  height: 360px;
}

/** Product Details iFrame **/
iframe {
  width: 100%;
  height: 360px;
}
